import { graphql } from 'gatsby';
import * as React from 'react';
import ContactInfo from '../components/global/contact-info/contact-info';
import FullWidthImage from '../components/global/full-width-image/full-width-image';
import GridGallery from '../components/global/grid-gallery/grid-gallery';
import HeaderAndText from '../components/global/header-and-text/header-and-text';
import Layout from '../components/global/layout/layout';
import News from '../components/global/news/news';

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        titleSuffix
        titleDivider
      }
    }
    restaurant: file(name: {eq: "restaurant-3"}) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logo: file(name: {eq: "logo-schloss-westerburg"}) {
      publicURL
    }
    gallery: allFile(filter: {name: {glob: "ahnensaal|hochzeit|schlosskueche-1|rittersaal-1"}}, sort: {fields: name}) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            originalName
          }
        }
      }
    }
    prismicHomepage {
      data {
        title_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        articles {
          link {
            uid
            document {
              ... on PrismicNewsArticle {
                uid
                publish_date: first_publication_date(formatString: "DD.MM.YYYY")
                data {
                  title {
                    text
                  }
                  shorttext {
                    text
                  }
                  image {
                    thumbnail {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 2400) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  body {
                    ... on PrismicNewsArticleBodyText {
                      slice_type
                      primary {
                        text {
                          html
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => {
  const galleryData = props.data.gallery.nodes.map(node => {
    let link;

    if (node.childImageSharp.fluid.originalName === 'schlosskueche-1.jpg') {
      link = {
        to: '/raeumlichkeiten',
        text: 'Unsere Räumlichkeiten'
      };
    }

    return {
      fluid: node.childImageSharp.fluid,
      link
    };
  })
  return (
    <Layout wrapperClassName="bg-pattern-gold"
            title="Herzlich Willkommen"
            description="Feiern, Tagen und Genießen in der stilvollen und der charmanten Atmosphäre des Westerburger Schlosses."
            titleSuffix={props.data.site.siteMetadata.titleSuffix}
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <FullWidthImage tag="header" fluid={props.data.prismicHomepage.data.title_image.localFile.childImageSharp.fluid} link={{ to: '/news/informationen-zur-wiedereroeffnung', text: 'Informationen zur Wiedereröffnung' }}/>
      <HeaderAndText
        heading="Willkommen auf Schloss Westerburg"
        image={{
          src: props.data.logo.publicURL,
          alt: 'Logo von Schloss Westerburg'
        }}
        text={[
          "Feiern, Tagen und Genießen in der stilvollen und der charmanten Atmosphäre des Westerburger Schlosses. Hinter dieser Beschreibung verbirgt sich unser wichtigstes Anliegen: Ihnen – unserem Gast – einen unvergesslichen Aufenthalt in dem beinahe 1.000 Jahre alten Gemäuer zu gestalten.",
          "Von Natur aus sorgt ein solch außergewöhnlicher Veranstaltungsort für ein einzigartiges Ambiente. In der Kombination mit einem professionellen Service, einem hohen Maß an Individualität in der persönlichen Betreuung machen wir Ihren Abend mit Freunden in unserem Restaurant, Ihre Hochzeitsfeier oder auch Ihre Tagung in unseren mittelalterlichen Sälen zum unvergesslichen Erlebnis. Wir freuen uns darauf, Sie als unseren Gast begrüßen zu dürfen.",
          "Ihr Team von Schloss Westerburg"
        ]}
      />
      <HeaderAndText
        heading="Aktuelles von Schloss Westerburg"
      />
      <News articles={{ nodes: props.data.prismicHomepage.data.articles.map(data => data.link.document) }} hideMoreButton={true}/>
      <FullWidthImage tag="section" link={{ to: '/restaurant', text: 'Das Restaurant Semperfrey' }} fluid={props.data.restaurant.childImageSharp.fluid}/>
      <HeaderAndText
        heading="Unsere Räumlichkeiten sind ganz besondere Event-Locations"
        text={[
          "Die einzigartige romantische Atmosphäre von Schloss Westerburg schafft den idealen Rahmen für ein ganz besonderes Ereignis - Ihre Hochzeit. Ob es das kleine Hochzeitsessen nach der standesamtlichen Trauung sein soll oder das große Hochzeitsfest, gerne erfüllen wir Ihre individuellen Wünsche.",
          "Doch nicht nur Hochzeiten können auf Schloss Westerburg geifert werden. Gerne richten wir auch alle Arten von besonderen Ereignissen aus."
        ]}
      />
      <GridGallery images={galleryData}/>
      <ContactInfo/>
    </Layout>
  );
}
